import React from 'react';
import { Row, Col, Input, Form, Typography } from 'antd';
import { formatIndianRupee } from '../formUtils';

interface OtherFinancialSpreadingProps {
    FYCount: number;
    form: any;
    colSpan: number;
    labelColSpan: number;
    viewModeActive: boolean;
}

const OtherFinancialSpreadingComponent: React.FC<OtherFinancialSpreadingProps> = ({ FYCount, form, colSpan, viewModeActive, labelColSpan }) => {
    const INR_SYMBOL: string = '\u20B9';
    
    return (
        <>

            <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: viewModeActive?'20px':'0px', borderBottom: '1px #E8E8E8 solid' }}>
                <Col span={labelColSpan}>
                    <Typography style={{ marginLeft: '20px', fontWeight: '600' }}>Net Revenue</Typography>
                </Col>
                {Array.from({ length: FYCount }, (_, ind) => (
                    <Col span={colSpan} key={ind}>
                        {viewModeActive ? (
                            <Typography style={{ fontWeight: '600', paddingLeft: '10px' }}>
                                <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue(['netRevenue', ind]))}`}</span>
                            </Typography>
                        ) : (
                            <Form.Item
                                name={['netRevenue', ind]}
                                rules={[{ required: true, message: 'Please enter Net Revenue value' }]}
                            >
                                <Input style={{ fontWeight: '600' }} type="number" />
                            </Form.Item>
                        )}
                    </Col>
                ))}
            </Row>

            <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: viewModeActive?'20px':'0px', borderBottom: '1px #E8E8E8 solid' }}>
                <Col span={labelColSpan}>
                    <Typography style={{ marginLeft: '20px', fontWeight: '600' }}>Other Income</Typography>
                </Col>
                {Array.from({ length: FYCount }, (_, ind) => (
                    <Col span={colSpan} key={ind}>
                        {viewModeActive ? (
                            <Typography style={{ fontWeight: '600', paddingLeft: '10px' }}>
                                <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue(['otherIncome', ind]))}`}</span>
                            </Typography>
                        ) : (
                            <Form.Item
                                name={['otherIncome', ind]}
                                rules={[{ required: true, message: 'Please enter Other Income value' }]}
                            >
                                <Input style={{ fontWeight: '600' }} type="number" />
                            </Form.Item>
                        )}
                    </Col>
                ))}
            </Row>

            <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: viewModeActive?'20px':'0px', borderBottom: '1px #E8E8E8 solid' }}>
                <Col span={labelColSpan}>
                    <Typography style={{ marginLeft: '20px', fontWeight: '600' }}>Operating Profits/Loss</Typography>
                </Col>
                {Array.from({ length: FYCount }, (_, ind) => (
                    <Col span={colSpan} key={ind}>
                        {viewModeActive ? (
                            <Typography style={{ fontWeight: '600', paddingLeft: '10px' }}>
                                <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue(['operatingProfits', ind]))}`}</span>
                            </Typography>
                        ) : (
                            <Form.Item
                                name={['operatingProfits', ind]}
                                rules={[{ required: true, message: 'Please enter Operating Profits/Loss value' }]}
                            >
                                <Input style={{ fontWeight: '600' }} type="number" />
                            </Form.Item>
                        )}
                    </Col>
                ))}
            </Row>

            <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: viewModeActive?'20px':'0px', borderBottom: '1px #E8E8E8 solid' }}>
                <Col span={labelColSpan}>
                    <Typography style={{ marginLeft: '20px', fontWeight: '600' }}>Net Profits /Loss</Typography>
                </Col>
                {Array.from({ length: FYCount }, (_, ind) => (
                    <Col span={colSpan} key={ind}>
                        {viewModeActive ? (
                            <Typography style={{ fontWeight: '600', paddingLeft: '10px' }}>
                                <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue(['netProfits', ind]))}`}</span>
                            </Typography>
                        ) : (
                            <Form.Item
                                name={['netProfits', ind]}
                                rules={[{ required: true, message: 'Please enter Net profits /loss value' }]}
                            >
                                <Input style={{ fontWeight: '600' }} type="number" />
                            </Form.Item>
                        )}
                    </Col>
                ))}
            </Row>
        </>
    );
};

export default OtherFinancialSpreadingComponent;
