import { LoadingOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Spin, Typography } from "antd";
import { ReactComponent as CrossIcon } from '../../SVGs/cross_icon.svg';
import { ReactComponent as TickIcon } from '../../SVGs/tick_icon.svg';
import { ReactComponent as DisableIcon } from '../../SVGs/disabled_icon.svg';
import _ from "lodash";
const { TextArea } = Input;

const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;

interface ModalProps {
    title: string;
    subTitle: string;
    loader: boolean;
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    remark: string;
    setRemark: (remark: string) => void;
    handler: () => void;
}

const OnHoldRemarkModal: React.FC<ModalProps> = ({ title, subTitle, loader, showModal, setShowModal, remark, setRemark, handler}) =>{
    return (
        <Modal
            title={<Typography style={{background: "#F6FAFF", fontSize: 16, fontWeight: 700, color: "#404040", marginBottom: 10}}>{title}</Typography>}
            centered
            open={showModal}
            closable={true}
            onCancel={() => setShowModal(false)}
            afterClose={() => setRemark("")}
            className="preview-modal"
            width={550}
            footer={<>
                <div className="edit-section">
                    <Button
                        ghost
                        danger
                        type="primary"
                        onClick={_ => !loader && setShowModal(false)}
                        className={"button-override danger"}
                        icon={ loader ? <Spin indicator={antIcon} /> : <CrossIcon />}
                        >
                        Cancel
                    </Button>
                    <Button
                        disabled={_.isEmpty(remark)}
                        type="primary"
                        onClick={_ => !loader && handler()}
                        className={_.isEmpty(remark) ? "button-override disable" : "button-override"}
                        icon={ loader ? <Spin indicator={antIcon} /> : <TickIcon /> }
                        >
                        Submit
                    </Button>
                </div>
            </>}
        >
            <Typography>{subTitle}</Typography>
            <TextArea rows={3} value={remark} onChange={(e) => setRemark(e.target.value)} placeholder="Remark" />
        </Modal>
    );
}

export default OnHoldRemarkModal;