import { Modal, Table, Tag } from "antd";
import { ReactComponent as DisabledIcon } from '../../SVGs/disabled_icon.svg';
import { LoadingOutlined } from "@ant-design/icons";
import type { TableProps } from 'antd';
import { getRequest } from "../../../../utils/handler/apiHandler";
import { useEffect, useState } from "react";
import Typography from "antd/es/typography/Typography";
import _ from "lodash";

const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;

interface ModalProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    data: any;
}

interface DataType {
    key: string;
    name: string;
    age: number;
    address: string;
  }

const ApprovalListModal: React.FC<ModalProps> = ({ showModal, setShowModal, data }) => {
    const [approver, setApprover] = useState([]);


    useEffect(() => { 
        !_.isEmpty(data?.creditSanction?.creditSanctionApproval) && getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/sanctionApprovers?sanctionId=${data?.creditSanction.id}`).then(res => {
            setApprover(res.data);
    })}, [data]);

    const columns: TableProps<DataType>['columns'] = [
        {
          // title: 'Approval Authority',
          title: (
            <div style={{ display:'flex'}}>
              <span style={{marginRight:'5px'}}>Approval Authority</span> 
            </div>
          ),
          dataIndex: 'authority',
          key: 'authority',
          // render: (authority) => <Typography style={{fontSize: 12, fontWeight: 400}}>{authority.authority}</Typography>,
          render: (authority: any) => {
            return( <div className="table-text-style">{authority?.authority}</div> )},
          width: "20%"
        },
        {
          // title: 'Approver Name',
          title: (
            <div style={{ display:'flex'}}>
              <span style={{marginRight:'5px'}}>Approver Name</span> 
            </div>
          ),
          dataIndex: 'authority',
          key: 'authority',
          // render: (authority) => <Typography style={{fontSize: 12, fontWeight: 400}}>{authority.name}</Typography>,
          render: (authority: any) => {
            return( <div className="table-text-style">{authority?.name}</div> )},
          width: "20%"
        },
        {
          // title: 'Approval Status',
          title: (
            <div style={{ display:'flex'}}>
              <span style={{marginRight:'5px'}}>Approval Status</span> 
            </div>
          ),
          dataIndex: 'status',
          key: 'status',
          // render: (status, record: any) => <Tag style={{color: record?.textColor, backgroundColor: record?.backgroundColor, border: "none"}}>{status}</Tag>,
          render: (status, record: any) => {
            return( <div className="table-text-style"><Tag style={{color: record?.textColor, backgroundColor: record?.backgroundColor, border: "none"}}>{status}</Tag></div> )},
          width: "20%"
        },
        {
          // title: 'Remark',
          title: (
            <div style={{ display:'flex'}}>
              <span style={{marginRight:'5px'}}>Remark</span> 
            </div>
          ),
          dataIndex: 'remark',
          key: 'remark',
          // render: (remark) => <ParagraphComponent remark={remark} />,
          render: (remark: any) => {
            return( <div className="table-text-style"><ParagraphComponent remark={remark} /></div> )},
          width: "40%"
        },
      ];

    return (
        <Modal
            centered
            open={showModal}
            onCancel={() => setShowModal(false)}
            afterClose={() => {}}
            className="preview-modal"
            footer={false}
            width={1000}
        >   
            <Table 
                style={{marginTop: 20}}
                dataSource={approver}
                columns={columns}
                components={{
                    header: {
                      wrapper: (props: any) => <thead style={{ backgroundColor: '#CEE6FA', height: '3.5rem' }}>{props.children}</thead>,
                    },
                  }}
                pagination={false}
            />
        </Modal>
    );
}

export default ApprovalListModal;

interface ParagraphComponentProps {
  remark: string;
}

const ParagraphComponent: React.FC<ParagraphComponentProps>  = ({ remark }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return <div style={{ maxWidth: '500px' }}>
  <p style={{ 
    overflow: 'hidden', 
    display: isExpanded ? '' : '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: isExpanded ? 'none' : 4, 
    lineHeight: '1.5em', 
    maxHeight: isExpanded ? 'none' : '6em',
    fontSize: 12, 
    fontWeight: remark ? 400 : 500, 
    color: remark ? "#000000" : "#AFAFAF"
  }}>
    {remark || "No Remark"}
  </p>
  {(remark && remark.length > 350) && <button onClick={toggleReadMore} style={{ border: 'none', background: 'transparent', color: 'blue', cursor: 'pointer' }}>
    {isExpanded ? 'Read Less' : 'Read More'}
  </button>}
</div>
}