import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, message, Modal, Row, Select, Spin, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { ReactComponent as CrossIcon } from '../../SVGs/cross_icon.svg';
import { ReactComponent as DeleteIcon } from '../../SVGs/delete_icon.svg';
import { ReactComponent as AddIcon } from '../../SVGs/add_icon.svg';
import { ReactComponent as DownloadIcon } from '../../SVGs/download_icon.svg';
import { ReactComponent as UploadIcon } from '../../SVGs/upload_icon.svg';
import { ReactComponent as RightArrowIcon } from '../../SVGs/right_arrow_icon.svg';
import { getRequest, postRequest, putRequest } from "../../../../utils/handler/apiHandler";
import currencyFormatter from "../../../../utils/NumberFormater";
import UploadSanctionModal from "./UplaodSanctionModal";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import dayjs from "dayjs";
import _ from "lodash";
import axios from "axios";
import { store } from "../../../../app/store";
const { Option } = Select;

const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;

interface ModalProps {
    showModal : boolean;
    setShowModal: (showModal: boolean) => void;
    data: any;
    setData: (data: any) => void;
}

const refundableSecurityTypeOptions = [
    { label: 'LR', value: 'LR' },
    { label: 'TIV', value: 'TIV' },
    { label: 'Fixed Value', value: 'Fixed Value'}
];

const lrFrequencyTypeOptions = [
    { label: 'Arrear', value: 'Arrear' },
    { label: 'Advance', value: 'Advance' },
];

const paymentModeOptions = [
    { label: 'NACH mandate', value: 'NACH mandate' },
    { label: 'PDC', value: 'PDC' },
];


const SanctionFormModal: React.FC<ModalProps> = ({ showModal, setShowModal, data, setData }) => {
    const [form] = Form.useForm();
    const [noteText, setNoteText] = useState<string>("");
    const [vehicles, setVehicles] = useState([]);
    const [investmentValuePerVehicle, setInvestmentValuePerVehicle] = useState(0);
    const [noOfVehicles, setNoOfVehicles] = useState(0);
    const [apiToggle, setApiToggle] = useState(false);
    const [formStep, setFormStep] = useState(0);
    const [generateSanction, setGenerateSanction] = useState<any>({});
    const [loadSanctionDoc, setLoadSanctionDoc] = useState<boolean>(false);
    const [uploadModal, setUploadModal] = useState<boolean>(false);
    const [file, setFile] = useState<any>(null);
    const [validFrom, setValidFrom] = useState<any>(null);
    const accessToken = store.getState().auth.accessToken;
    const [loader, setLoader] = useState<boolean>(false);
    const [sanctionDraftLoader, setSanctionDraftLoader] = useState<boolean>(false);

    let initialValue = [{}];

    // Set Form fields is sanction already exists
    useEffect(() => {
            if(data?.creditSanction.active) {
                const creditSanctionRequirements: any = [];
                data?.creditSanction.creditSanctionRequirements.map((e: any) => {
                    creditSanctionRequirements.push({
                        id: e.id,
                        investmentValuePerVehicle: e.investmentValuePerVehicle,
                        noOfVehicles: e.noOfVehicles,
                        totalValue: e.totalValue,
                        vehicleCategory: e.vehicleCategory && JSON.parse(e.vehicleCategory)["name"],
                        vehicleCategoryObj: e.vehicleCategory
                    })
                });

                form.setFieldsValue({
                    creditLimit: data?.creditSanction.creditLimit,
                    refundableSecurityType: data?.creditSanction.refundableSecurityType,
                    refundableSecurityAmount: data?.creditSanction.refundableSecurityAmount,
                    lrFrequencyType: data?.creditSanction.lrFrequencyType,
                    paymentMode : data?.creditSanction.paymentMode,
                    validTo: dayjs(data?.creditSanction.validTo.split("T")[0]),
                    validFrom: dayjs(data?.creditSanction.validFrom.split("T")[0]),
                    creditCondition: data?.creditSanction.creditCondition,
                    creditSanctionRequirements: creditSanctionRequirements,
                });
            } else {
                form.setFieldsValue({
                    refundableSecurityType: "LR",
                    lrFrequencyType: "Arrear",
                    paymentMode : "NACH mandate"
                });
            }
    }, [data]);

    const getVehicleCategory = () => {
        postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/master/vehicles`, {}).then(res => {
            setVehicles(res.data);
        })
    };

    // Handle submit based on condition either sanction exists or doesn't exist
    const handler = async () => {
        try {
            await form.validateFields();

            setLoader(true);
            let creditSanctionRequirements: any = [];
            form.getFieldsValue().creditSanctionRequirements.map((e: any) => {
                creditSanctionRequirements.push({
                    id: e.id || null,
                    vehicleCategory: (typeof e.vehicleCategoryObj === "string") ? e.vehicleCategoryObj : JSON.stringify(e.vehicleCategoryObj),
                    noOfVehicles: e.noOfVehicles,
                    investmentValuePerVehicle: e.investmentValuePerVehicle,
                    totalValue: e.investmentValuePerVehicle*e.noOfVehicles
                })
            })
    
            const payload = {
                    ...form.getFieldsValue(true),
                    validTo: dayjs(form.getFieldsValue(true).validTo).format("YYYY-MM-DD"),
                    validFrom: dayjs(form.getFieldsValue(true).validFrom).format("YYYY-MM-DD"),
                    creditSanctionRequirements
            }
    
            {
                data?.creditSanction.active 
                ?  putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/sanction/${data?.creditSanction.id}?creditRequestId=${data?.creditRequestId}`, payload).then(res => {
                    message.destroy();
                    message.success(res.data);
                    setApiToggle(!apiToggle);
                    setFormStep(1);
                    setLoader(false);
                })
                : putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/${data?.creditRequestId}`, {creditSanction: payload}).then(res => {
                    message.destroy();
                    message.success(res.data);
                    setApiToggle(!apiToggle);
                    setFormStep(1);
                    setLoader(false);
                })
            }
        } catch(err) {
            console.log("Error: ", err);
            setLoader(false);
        }
    }

    useEffect(() => {
        form.resetFields()
        data && getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit?creditRequestId=${data?.creditRequestId}`).then(res => {
            setData(res.data)
        });

        setLoadSanctionDoc(true);
        data && getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/report/generateSanction?creditRequestId=${data?.creditRequestId}`).then(res => {
            setGenerateSanction(res.data)
            setLoadSanctionDoc(false)
        });
    }, [apiToggle]);

    useEffect(() => {
        console.log("State: ", generateSanction);
    }, [generateSanction])

    const optionsToRender = (options: any[], identifier: any) => {
        if(options && options.length > 0)
        return options?.map((value: any) => (
            <Option key={value.id} value={value[identifier]}>
            {value[identifier]}
            </Option>
        ));
    };

    const handleUploadSanction = () => {
        const creditRequestId: any = data?.creditRequestId;
        setGenerateSanction({})
        try {
            let bodyFormData = new FormData();
            bodyFormData.append('file', file);
            bodyFormData.append('creditRequestId', creditRequestId);

            axios({
                method: "post",
                url: `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/file/uploadSanction`,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data", "Authorization": `Bearer ${accessToken}` },
                }).then(res => {

                console.log("Document Url: ", res.data.DocumentUrl);

                if(res.status === 200) { 
                    let sanction = {
                        ...generateSanction,
                        pdfFileUrl: res.data.DocumentUrl
                    }
                    setGenerateSanction(sanction);
                    setFile(null);
                    setLoadSanctionDoc(false);
                    setUploadModal(false);
                }
            });
        } catch (err: any) {
            console.log("Error: ", err);
            message.error(err?.response?.data?.message);
        }
    }

    const handleGenerateDraftSanction = () => {
        const creditRequestId: any = data?.creditRequestId;
        const sanction: any = data?.creditSanction.id;
        setSanctionDraftLoader(true);
        putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/sanction/${sanction}?creditRequestId=${creditRequestId}&creditStatusTypeId=${11}`, {}).then(res => {
            setShowModal(false);
            setSanctionDraftLoader(false);
            setTimeout(() => window.location.reload(), 1000)
        });
    }

    return (
        <div>
            <Modal
                title={<Typography style={{background: "#F6FAFF", fontSize: 16, fontWeight: 500, color: "#404040"}}>{formStep === 0 ? "Sanction Form" : "Review Draft Sanction Letter"}</Typography>}
                centered
                open={showModal}
                onCancel={() => setShowModal(false)}
                // afterClose={() => form.resetFields()}
                className="preview-modal"
                width={1000}
                footer={<>
                    {formStep === 0 && <div className="edit-section">
                        <Button
                            ghost
                            danger
                            onClick={_ => setShowModal(false)}
                            className="button-override danger"
                            icon={<CrossIcon />}
                            >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            onClick={_ => !loader && handler()}
                            className="button-override"
                            >
                            Proceed
                            { loader ? <Spin indicator={antIcon} /> : <RightArrowIcon style={{marginLeft: 8}} /> }
                        </Button>
                    </div>}
                    {formStep === 1 && <div className="edit-section">
                        <Button
                            ghost
                            danger
                            className="button-override danger"
                            onClick={_ => setShowModal(false)}
                            icon={<CrossIcon />}
                            >
                            Cancel
                        </Button>

                        <a href={generateSanction?.wordFileUrl} style={{ marginLeft: 10}}>
                        <Button
                            ghost
                            type="primary"
                            className="button-override primary"
                            icon={<DownloadIcon />}
                            >
                            Download Template
                        </Button>
                        </a>

                        <Button
                            ghost
                            type="primary"
                            onClick={_ => setUploadModal(true)}
                            className="button-override primary"
                            icon={<UploadIcon />}
                            >
                            Upload
                        </Button>
                        <Button
                            ghost
                            type="primary"
                            onClick={_ => {setFormStep(0); setGenerateSanction({})}}
                            className="button-override primary"
                            icon={<RightArrowIcon style={{rotate: "180deg"}} />}
                            >
                            Go Back
                        </Button>
                        <Button
                            type="primary"
                            onClick={handleGenerateDraftSanction}
                            className="button-override"
                            >
                            Generate Draft Sanction Letter
                            { sanctionDraftLoader ? <Spin indicator={antIcon} /> : <RightArrowIcon style={{marginLeft: 8}} /> }
                        </Button>
                    </div>}
                </>}
            >
                <div>
                    {formStep === 0 && <div className="sanction-form">
                        <Typography>Fill this form to start the approval process</Typography>
                        <Card style={{marginTop: 10}} bordered={false}>
                            <Form
                            form={form}
                            requiredMark={false}
                            layout="vertical"
                            name="credit_request_form"
                            >
                                <Col>
                                    <Col style={{display: "flex", gap: 10}}>
                                        <Form.Item
                                            name="creditLimit"
                                            style={{minWidth:'50%'}}
                                            label={ <p className="description-label">Credit Limit</p> }
                                            rules={[{ required: true, message: 'Please enter Credit Limit!' }]}
                                        >
                                            <InputNumber style={{width: "100%"}} className="input-number-override" placeholder="Credit Limit" />
                                        </Form.Item>

                                        <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                                            <p className="description-label" style={{padding: "0 0 8px", marginTop: 5}}>Refundable Security Deposit Per Car</p>
                                            <div style={{display: "flex", width: "100%"}}>
                                                <Form.Item
                                                name="refundableSecurityType"
                                                rules={[{ required: true, message: 'Please enter Refundable Security Type!' }]}
                                                style={{width: "40%"}}
                                                >
                                                    <Select
                                                    placeholder="Refundable Security Type"
                                                    options={refundableSecurityTypeOptions}
                                                    className="select-custom-input-override"
                                                    style={{width: "100%"}} 
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                name="refundableSecurityAmount"
                                                rules={[{ required: true, message: 'Please enter Refundable Security Deposit Per Car!' }]}
                                                style={{width: "100%"}} 
                                                >
                                                    <InputNumber 
                                                    className="input-custom-number-override" 
                                                    placeholder="Refundable Security Deposit Per Car"
                                                    style={{width: "100%"}} 
                                                />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col style={{display: "flex", gap: 10}}>
                                        {/* <Form.Item
                                            name="lrFrequencyType"
                                            style={{minWidth:'50%'}}
                                            label={ <p className="description-label">LR Payment Frequency</p> }
                                            rules={[{ required: true, message: 'Please enter LR Payment Frequency!' }]}
                                        >
                                            <Select 
                                                options={lrFrequencyTypeOptions}
                                                className="select-input-override"
                                                style={{width: "100%"}} 
                                                placeholder="LR Payment Frequency"
                                            />
                                        </Form.Item> */}
                                        <Form.Item
                                            name="paymentMode"
                                            style={{minWidth:'50%'}}
                                            label={ <p className="description-label">Payment Mode</p> }
                                            rules={[{ required: true, message: 'Please enter Payment Mode!' }]}
                                        >
                                            <Select 
                                                options={paymentModeOptions}
                                                className="select-input-override"
                                                style={{width: "100%"}} 
                                                placeholder="Payment Mode"
                                            />
                                        </Form.Item>

                                        <Col style={{display:"flex", width: "100%", gap: 10}}>
                                        <Form.Item
                                                name="validFrom"
                                                style={{minWidth:'49%'}}
                                                label={ <p className="description-label">Valid From</p> }
                                                rules={[{ required: true, message: 'Please enter Valid From!' }]}
                                            >
                                                <DatePicker className="date-picker-override" style={{width: "100%"}} onChange={(e) => setValidFrom(e)} />
                                            </Form.Item>
                                            <Form.Item
                                                name="validTo"
                                                style={{minWidth:'49%'}}
                                                label={ <p className="description-label">Valid Till</p> }
                                                rules={[{ required: true, message: 'Please enter Valid Till!' }]}
                                            >
                                                <DatePicker className="date-picker-override" style={{width: "100%"}} minDate={dayjs(validFrom, 'YYYY-MM-DD')} />
                                            </Form.Item>
                                        </Col>
                                    </Col>

                                    <Form.Item
                                        name="creditCondition"
                                        label={ <p className="description-label">Credit conditions imposed</p> }
                                        rules={[{ required: true, message: 'Please enter credit condition!' }]}
                                    >
                                    <ReactQuill
                                        theme="snow"
                                        value={noteText}
                                        onChange={(value) => setNoteText(value)}
                                        placeholder='Type Credit Conditions here'
                                        style={{display : "flex", flexDirection : "column-reverse", height : "150px"}}
                                        modules={{
                                        toolbar: [
                                            ['bold', 'italic', 'underline', 'strike'],
                                            ['code-block'],
                                            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                            [{ 'align': [] }],
                                            ['link', true],
                                            ['clean'],
                                            [{ 'header': [1, 2, false] }],
                                        ],
                                        }}
                                    />
                                    </Form.Item>
                                </Col>

                                {/* Dynamic field for credit requirement */}
                                <Form.List name="creditSanctionRequirements" initialValue={initialValue}>
                                {(fields, { add, remove }) => (
                                    <>
                                        <Row justify="space-between" align="middle" style={{marginTop: 20}}>
                                            <Typography style={{ fontSize: 14, fontWeight: 500}}>Approved Vehicles</Typography>
                                            <Button type="link" style={{fontSize: 12, fontWeight: 400}} icon={<AddIcon />} 
                                                onClick={async()=>{try { await form.validateFields(); add(); }catch(err){}}} >
                                                Add Requirement</Button>
                                        </Row>
                                        <Row style={{display: "flex", flexWrap: "nowrap", fontWeight: 500, backgroundColor: "#CEE6FA", padding: 10}}>
                                            <Col style={{width: "30%", padding: "0 0 0 10px"}}>Vehicle Category</Col>
                                            <Col style={{width: "30%", padding: "0 0 0 10px"}}>No. of Vehicle</Col>
                                            <Col style={{width: "30%", padding: "0 0 0 10px"}}>Investment value per vehicle</Col>
                                            <Col style={{width: "20%", padding: "0 0 0 10px"}}>Total value</Col>
                                        </Row>

                                        <div style={{maxHeight: 200, overflow: "scroll"}}>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Row className="deleteIconHover" key={key} style={{display: "flex", flexWrap: "nowrap", minHeight: 50, padding: 10, borderBottom: "var(--Number, 1px) solid #E8E8E8"}}>
                                                <Col style={{width: "30%", padding: "0 0 0 10px"}}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'vehicleCategory']}
                                                        style={{margin: 0}}
                                                        rules={[{ required: true, message: "" }]}
                                                    >
                                                        <Select 
                                                            className="select-input-override" 
                                                            placeholder="Vehicle Category" 
                                                            onClick={getVehicleCategory} 
                                                            onChange={e => form.setFieldValue(["creditSanctionRequirements", name, "vehicleCategoryObj"], (vehicles.filter(vehicle => vehicle["name"] === e)[0]))}>
                                                            {optionsToRender(vehicles, "name")}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col style={{width: "30%", padding: "0 0 0 10px"}}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'noOfVehicles']}
                                                        style={{margin: 0}}
                                                        rules={[{ required: true, message: "" }]}
                                                    >
                                                        <InputNumber style={{width: "100%"}} className="input-number-override" placeholder="No. of Vehicle" onChange={(e: any) => setNoOfVehicles(e) }/>
                                                    </Form.Item>
                                                </Col>
                                                <Col style={{width: "30%", padding: "0 0 0 10px"}}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'investmentValuePerVehicle']}
                                                        style={{margin: 0}}
                                                        rules={[{ required: true, message: "" }]}
                                                    >
                                                        <InputNumber style={{width: "100%"}} className="input-number-override" placeholder="Investment value per vehicle" onChange={(e: any) => setInvestmentValuePerVehicle(e) } />
                                                    </Form.Item>
                                                </Col>
                                                <Col style={{width: "20%", padding: "0 0 0 10px"}}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'totalValue']}
                                                        style={{margin: 0}}
                                                    >
                                                        <Typography style={{fontSize: 14, fontWeight: 500, width: "100%"}}>
                                                        {currencyFormatter(~~form.getFieldValue(["creditSanctionRequirements", name, "noOfVehicles"]) * ~~form.getFieldValue(["creditSanctionRequirements", name, "investmentValuePerVehicle"]))}
                                                        </Typography>
                                                    </Form.Item>
                                                </Col>
                                                {
                                                    form.getFieldValue("creditSanctionRequirements").length > 1 && <DeleteIcon onClick={_=> remove(name)} className="deleteIcon"/>
                                                }
                                            </Row>
                                        ))}
                                        </div>
                                    </>
                                )}
                                </Form.List>
                            </Form>
                        </Card>
                    </div>}
                    {formStep === 1 && <div className="sanction-form">
                        <Card style={{marginTop: 10}} bordered={false}>
                        {_.isEmpty(generateSanction) ? <div style={{height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}><Spin size="large" /></div> : <iframe
                            id="preview"
                            src={generateSanction?.pdfFileUrl}
                            style={{ width: "100%", height: "60vh", border: "none" }}
                        />}
                        </Card>
                    </div>}

                </div>
            </Modal>
            <UploadSanctionModal title="Upload Sanction Letter" showModal={uploadModal} setShowModal={setUploadModal} file={file} setFile={setFile} upload={handleUploadSanction} />
        </div>
    );
}

export default SanctionFormModal;